/* quiz.module.css */

.quizContainer {
  background-image: url("./assets/image/back.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 80vw;
  max-width: 80%;
  text-align: center;
  margin-bottom: 20px;
}
.card h2 {
  font-size: 75px;
}
.card p {
  font-size: 45px;
}

.button {
  background-color: #008cba;
  color: white;
  padding: 15px;
  font-size: 18px;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  width: 80%;
  max-width: 300px;
  text-align: center;
  margin: 10px auto;
}

.button:hover {
  background-color: #006f9a;
}

.answerImage {
  width: 150px;
  height: auto;
  margin-bottom: 45px;
}
